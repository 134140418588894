import Axios from "axios"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import "../components/i18n"
import Image from "../components/image"
import Layout from "../components/layout"
import Loader from "../components/loader"
import SEO from "../components/seo"
import "../styles/main.scss"
// const getLanguage = () => i18next.language || window.localStorage.i18nextLng

const PrivacyPage = () => {
  const [data, setData] = useState(null)
  const [axiosError, setAxiosError] = useState(false)
  const { t, ready, i18n } = useTranslation("index")
  const fetch = lang => {
    console.log(lang, "lang")
    const language = lang || i18n.language
    Axios({
      method: "get",
      url: process.env.GATSBY_STRAPI_HOST.concat(
        "/privacy-policies?_sort=createdAt:DESC&_limit=1&_start=0"
      ),
    })
      .then(result => {
        if (result) {
          setAxiosError(false)
          setData(
            language === "sr" ? result.data[0].text : result.data[0].text_en
          )
        } else setAxiosError(true)
      })
      .catch(err => {
        console.log(err)
        setAxiosError(true)
      })
  }
  useEffect(() => {
    i18n.on("languageChanged", lang => fetch(lang))
  })
  useEffect(() => {
    fetch()
  }, [])

  return !ready ? (
    <Loader />
  ) : (
    <Layout withBg>
      <SEO title="Home" />

      {data &&
        !axiosError &&
        data?.split("\n").map((item, i) => {
          switch (item[0]) {
            case "#":
              return <h1 key={i}>{item.slice(1)}</h1>
            case "%":
              return <h2 key={i}>{item.slice(1)}</h2>
            default:
              return (
                <p key={i} className="basic_text">
                  {item}
                </p>
              )
          }
        })}

      <div className="big_logo_container">
        <Image alt="logo" path="zakazi-logo.svg" style="big_logo" />
      </div>
    </Layout>
  )
}

export default PrivacyPage
